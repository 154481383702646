/** @jsx jsx */
import { jsx } from "theme-ui"

// https://codepen.io/wetandsalty/pen/XBaEEd?editors=1100
const SectionSeparator = () => {
  return (
    <hr
      sx={{
        display: "block",
        margin: "3rem auto",
        width: "30%",
        border: "none",
        height: "2px",
        background: theme =>
          `linear-gradient(to right, ${theme.colors.background}, ${theme.colors.lightened}, ${theme.colors.background})`,
      }}
    />
  )
}

export default SectionSeparator
