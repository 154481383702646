/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"

import AnimatedLink from "./animated-link"

const PostCard = ({ article, path, linkStyles }) => {
  const [colorMode] = useColorMode()
  const isDark = colorMode === `dark`

  const boxShadow = isDark
    ? `rgba(50, 33, 84, 0.25)`
    : `rgba(135, 187, 162, 0.25)`

  // const boxShadow = isDark
  //   ? `rgba(95, 82, 122, 0.25)`
  //   : `rgba(153, 188, 255, 0.15)`

  return (
    <li
      sx={{
        boxShadow: `5px 5px 9px ${boxShadow}`,
        maxWidth: "70ch",
        justifySelf: "center",
        listStyle: "none",
        boxSizing: "border-box",
        padding: "8px 16px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "5px",
      }}
    >
      <h3
        sx={{
          margin: "0",
          marginBottom: "0.25rem",
          fontSize: ["1.15rem", "1.2rem", "1.25rem", "1.5rem"],
        }}
      >
        <AnimatedLink
          isInternal={true}
          href={path}
          styles={{
            backgroundImage: theme =>
              `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), linear-gradient(${theme.colors.secondary}, ${theme.colors.secondary})`,
            ...linkStyles,
          }}
        >
          {article.node.frontmatter.title}
        </AnimatedLink>
      </h3>
      <small>{article.node.frontmatter.date}</small>
      <p>{article.node.excerpt}</p>
      <AnimatedLink
        isInternal={true}
        href={path}
        styles={{
          width: "fit-content",
          backgroundImage: theme =>
            `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0)), linear-gradient(${theme.colors.secondary}, ${theme.colors.secondary})`,
          ...linkStyles,
        }}
      >
        Read more »
      </AnimatedLink>
    </li>
  )
}

export default PostCard
