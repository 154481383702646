/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import PostCard from "../components/post-card"
import PortfolioCard from "../components/portfolio-card"
import SectionSeparator from "../components/section-separator"

const ulStyles = {
  padding: "0 1rem",
  display: "grid",
  gridTemplateColumns: "1fr",
  gridRowGap: "2rem",
}

const sectionStyles = {
  width: ["90%", "90%", "90%", "80%"],
  maxWidth: "calc(70ch + 350px + 32px)",
  margin: "0 auto",
}

const headings = {
  fontSize: ["1.5rem", "1.5rem", "2rem", "2rem"],
}

const IndexPage = ({ data }) => {
  const portfolioPieces = data.portfolio.edges
  const blogPosts = data.blog.edges

  return (
    <div
      sx={{
        width: "100%",

        p: {
          fontSize: "1.1rem",
        },
      }}
    >
      <section
        sx={{
          ...sectionStyles,
        }}
      >
        <h3 sx={headings}>About Me</h3>

        <div sx={{ maxWidth: "960px", margin: "0 auto", padding: "0 1rem" }}>
          <p>
            I graduated from Brigham Young University in 2018 with two
            Bachelor's Degrees in Linguistics and Italian Studies. I missed
            learning. I missed using my skills and knowledge to empower others
            to increase their capacity to do. So a couple of years later, I
            completed Lambda School's full stack web development track, playing
            full-time mom during the day and part-time dev student at night.
          </p>

          <p>After graduating, I spent 2+ years as a fullstack Developer Experience engineer at Apollo GraphQL, building out their docs site and custom learning platform.</p>

          <p>
            When I'm not writing code or spending time with my family, you can
            find me playing the piano, running, cooking, and playing Nintendo
            games.
          </p>
        </div>
      </section>

      <SectionSeparator />

      <section
        sx={{
          margin: "0",
        }}
      >
        <div
          sx={{
            ...sectionStyles,
            boxSizing: "border-box",
            padding: "0",
          }}
        >
          <h3 sx={headings}>Recent Blog Posts</h3>
          <ul
            sx={{
              ...ulStyles,
              gridTemplateColumns: [
                "1fr",
                "1fr",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
              ],
              gridColumnGap: "1rem",
              maxWidth: "960px",
              margin: "0 auto",
            }}
          >
            {blogPosts.map(node => (
              <PostCard
                key={node.node.fields.slug}
                article={node}
                path={node.node.fields.slug}
              ></PostCard>
            ))}
          </ul>
        </div>
      </section>

      <SectionSeparator />

      <section
        sx={{
          ...sectionStyles,
        }}
      >
        <h3 sx={headings}>Projects</h3>
        <ul sx={{ ...ulStyles }}>
          {portfolioPieces.map(({ node }) => (
            <PortfolioCard
              key={node.fields.slug}
              article={node}
            ></PortfolioCard>
          ))}
        </ul>
      </section>
    </div>
  )
}

export default IndexPage

export const postsQuery = graphql`
  query {
    blog: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: {
          templateKey: { eq: "post-list-template" }
          draft: { ne: true }
        }
      }
      limit: 4
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
    portfolio: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { templateKey: { eq: "portfolio-list-template" } }
      }
      limit: 4
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            date(formatString: "MMM DD, YYYY")
            hero {
              childImageSharp {
                fluid(maxWidth: 675) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
            description
            summary
            url
            github
            stack
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
